<template>
	<div class="card rounded-4 overflow-hidden">
		<div class="card-body p-4 placeholder-glow">
			<div class="row">
				<div class="col vstack gap-1">
					<span class="h6 font-semibold text-muted text-sm d-block placeholder">&nbsp;</span>
					<div class="hstack gap-1 justify-content-between">
						<span class="h4 ls-tighter lh-xs font-bold placeholder col-3">&nbsp;</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>