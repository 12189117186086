<template>
	<LayoutMain>
		<LayoutHeaderSection>
			<template #title>
				<LayoutTextSection text="Listado de usuarios" :breadcrumb="true" />
			</template>
		</LayoutHeaderSection>
		<main class="container-md pt-5 pb-10 pb-0 vstack gap-10">
			<LayoutTableMain :results="results" :loading="loading" @goPage="goPage">
				<template #filters>
					<div class="input-group input-group-sm rounded-pill">
						<span class="input-group-text bg-white rounded-start-pill pe-0" id="basic-addon1"><i class="bi bi-search"></i></span>
						<input type="text" v-model="filters.search" class="form-control border-start-0 rounded-end-pill" placeholder="Buscar..." />
					</div>
				</template>
				<template #body>
					<AdminUserTableRow v-for="data in results?.data" v-bind:key="data.id" :user="data" @impersonate="impersonate" @remove="remove" />
				</template>
			</LayoutTableMain>
		</main>

		<CommonModalAction ref="modalRemove" title="Eliminar usuario" description="¿Desea eliminar este usuario? Al eliminarlo, ya no tendrá acceso a la plataforma" submitText="Eliminar" submitClass="btn-danger" :target="target" @success="destroy" />
	</LayoutMain>
</template>

<script>
	import { ref, reactive, watch, onMounted } from 'vue';
	import router from '@/router';
	import store from '@/store';
	import composableForm from '@/composables/form';
	import AdminUserRepository from '@/repositories/Admin/UserRepository';

	export default {
		setup() {
			const { loading, catchError } = composableForm();
			const results = ref({});
			const filters = reactive({
				search: null,
				orderBy: 'created_at',
				orderMethod: 'desc',
				take: 20,
				page: 1
			});

			const target = ref(null);
			const modalRemove = ref(null);
			const remove = (user) => {
				target.value = user;
				modalRemove.value.show();
			}
			const destroy = (user) => {
				AdminUserRepository.destroy(user.id).then(getResults).catch((e) => catchError(e, true));
			}

			const impersonate = (user) => {
				AdminUserRepository.impersonate(user.id).then((response) => {
					store.dispatch('auth/setToken', response.data);
					store.dispatch('auth/me').then(() => {
						router.push({ name: 'home' });
					}).catch((e) => catchError(e, true));
				}).catch((e) => catchError(e, true));
			}

			const getResults = async () => {
				loading.value = true;

				await AdminUserRepository.index(filters).then((response) => {
					results.value = response.data;
				}).catch((e) => {
					catchError(e, true);
					router.push({ name: 'home' });
				});

				loading.value = false;
			}

			const goPage = (page) => {
				filters.page = page;
				getResults();
			}

			const timeout = ref(null);
			watch(() => filters.search, () => {
				if(timeout.value) clearTimeout(timeout.value);
				timeout.value = setTimeout(() => goPage(1), 1000);
			});

			onMounted(getResults);

			return { loading, filters, results, goPage, target, modalRemove, remove, destroy, impersonate };
		}
	}
</script>