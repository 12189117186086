<template>
	<LayoutTableCommonRow>
		<LayoutTableCommonPartImage :src="image.file_url" size="md" />
		<LayoutTableCommonPartText :text="publication.title" :subtext="publication.code" />
		<LayoutTableCommonPartRaw class="text-end text-md fw-bold px-1" width="75">
			<div class="text-success d-inline" v-tooltip:left="`Likes`">
				<span class="pe-1">{{ publication.decisions_count }}</span>
				<i class="bi bi-heart-fill"></i>
			</div>
		</LayoutTableCommonPartRaw>
	</LayoutTableCommonRow>
</template>

<script>
	import _ from 'lodash';

	export default {
		props: {
			publication: Object
		},
		setup(props, { emit }) {
			const image = _.head(_.filter(props.publication.medias, { type: 'image' }));

			return { image };
		}
	}
</script>