<template>
	<LayoutTableCommonRow>
		<LayoutTableCommonPartImage :src="user.avatar_url" size="md" />
		<LayoutTableCommonPartText :text="user.name" :colspan="(index <= 2) ? 1 : 2" :subtext="`${user.searches_answered_count} ${(user.searches_answered_count == 1) ? 'respuesta' : 'respuestas'} de ${user.searches_shareable_count}`" />
		<LayoutTableCommonPartRaw class="text-end text-md fw-bold px-1" width="60" v-if="index <= 2">
			<div class="pe-2" style="color: #FFD700;" v-if="index == 0">
				<span class="pe-1">1º</span>
				<i class="bi bi-trophy-fill"></i>
			</div>
			<div class="pe-2" style="color: #C0C0C0;" v-else-if="index == 1">
				<span class="pe-1">2º</span>
				<i class="bi bi-trophy-fill"></i>
			</div>
			<div class="pe-2" style="color: #CD7F32;" v-else-if="index == 2">
				<span class="pe-1">3º</span>
				<i class="bi bi-trophy-fill"></i>
			</div>
		</LayoutTableCommonPartRaw>
	</LayoutTableCommonRow>
</template>

<script>
	import { computed } from 'vue';

	export default {
		props: {
			user: Object,
			index: Number
		}
	}
</script>