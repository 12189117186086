<template>
	<div class="row g-4">
		<div class="col">
			<AccountStatReportCommonCard title="Búsquedas totales" :value="`${report.SearchCount}`" />
		</div>
		<div class="col">
			<AccountStatReportCommonCard title="Publicadas" :value="`${report.SearchPublishedCount}`" />
		</div>
	</div>
	<div class="card rounded-4 shadow">
		<div class="card-body p-4 lh-sm">
			<div class="h5 mb-3">Usuarios con más publicaciones</div>
			<LayoutTableBasic :results="report.PublicationByUserCount">
				<template #body>
					<AccountStatReportCommonUserPublicationTableRow v-for="(data, index) in report.PublicationByUserCount" v-bind:key="data.id" :user="data" :index="index" />
				</template>
			</LayoutTableBasic>
		</div>
	</div>
	<div class="card rounded-4 shadow">
		<div class="card-body p-4 lh-sm">
			<div class="h5 mb-3">Usuarios que más búsquedas publicaron</div>
			<LayoutTableBasic :results="report.SearchByUserPublishedCount">
				<template #body>
					<AccountStatReportCommonUserPublishedTableRow v-for="(data, index) in report.SearchByUserPublishedCount" v-bind:key="data.id" :user="data" :index="index" />
				</template>
			</LayoutTableBasic>
		</div>
	</div>
	<div class="card rounded-4 shadow">
		<div class="card-body p-4 lh-sm">
			<div class="h5 mb-3">Publicaciones con más likes</div>
			<LayoutTableBasic :results="report.PublicationLikedCount">
				<template #body>
					<AccountStatReportCommonPublicationTableRow v-for="data in report.PublicationLikedCount" v-bind:key="data.id" :publication="data" />
				</template>
			</LayoutTableBasic>
		</div>
	</div>
</template>

<script>
	import { computed } from 'vue';

	export default {
		props: {
			report: Object
		},
		setup(props) {
			const percentage = computed(() => ((props.report.SearchShareableAnsweredCount / props.report.SearchShareableCount) * 100));
			const percentageClass = computed(() => {
				if(percentage.value >= 80) return 'bg-success';
				else if(percentage.value >= 50) return 'bg-warning';
				else if(percentage.value >= 80) return 'bg-danger';
			});

			return { percentage, percentageClass };
		}
	}
</script>