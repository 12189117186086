<template>
	<div class="card rounded-4">
		<div class="card-body p-4">
			<div class="row">
				<div class="col vstack gap-1">
					<span class="h6 font-semibold text-muted text-sm d-block">{{ title }}</span>
					<div class="hstack gap-1 justify-content-between">
						<span class="h4 ls-tighter lh-xs font-bold">{{ value }}</span>
						<span class="badge badge-pill lh-xs" :class="percentageClass" v-tooltip:left="percentageTooltip" v-if="percentage >= 0">{{ vueNumberFormat(percentage, { suffix: `%`, precision: 0 }) }}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			value: String,
			title: String,
			percentage: Number,
			percentageClass: String,
			percentageTooltip: String
		}
	}
</script>