<template>
	<div class="vstack">
		<div class="hstack gap-14 justify-content-between mb-4" v-if="title">
			<h5 class="py-2" v-if="title">{{ title }}</h5>
		</div>
		<div :class="{ 'table-responsive': responsive }">
			<table class="table" :class="tableClasses">
				<thead class="table-light" v-if="$slots.head">
					<slot name="thead" />
				</thead>
				<tbody v-if="$slots.body">
					<slot name="body" />
				</tbody>
			</table>
		</div>
		<div class="pt-4 hstack gap-4">
			<span class="text-muted text-sm lh-sm" v-if="results.length == 0">No hay resultados</span>
			<span class="text-muted text-sm lh-sm" v-else>{{ `${results.length} ${(results.length == 1) ? 'resultado' : 'resultados'}` }}</span>
		</div>
	</div>
</template>

<script>
	import { computed } from 'vue';

	export default {
		props: {
			title: String,
			nowrap: Boolean,
			results: {
				type: Array,
				default: []
			},
			responsive: {
				type: Boolean,
				default: false
			},
			tableClass: {
				type: String,
				default: 'table-compact'
			}
		},
		setup(props) {
			const tableClasses = [
				props.tableClass,
				(props.nowrap) ? 'table-nowrap' : null
			];

			return { tableClasses };
		}
	}
</script>