<template>
	<div class="row g-4">
		<div class="col">
			<AccountStatReportCommonCardPlaceholder />
		</div>
		<div class="col">
			<AccountStatReportCommonCardPlaceholder />
		</div>
	</div>
	<div class="card rounded-4 shadow placeholder-glow">
		<div class="card-body p-4 lh-sm">
			<div class="h5 placeholder col-6">&nbsp;</div>
		</div>
	</div>
	<div class="card rounded-4 shadow placeholder-glow">
		<div class="card-body p-4 lh-sm">
			<div class="h5 placeholder col-6">&nbsp;</div>
		</div>
	</div>
</template>