<template>
	<div class="hstack gap-2 align-items-center">
		<GroupCommonImage :src="imageUrl" size="sm" class="mx-auto" />
		<div class="flex-fill lh-xs text-limit">
			<div class="text-break text-truncate">{{ group?.name }}</div>
			<div class="text-muted text-sm d-block text-truncate" v-if="subtitle">{{ subtitle }}</div>
		</div>
	</div>
</template>

<script>
	import { computed } from 'vue';
	import assetsImgIconsNoImage from '@/assets/img/icons/no-image.jpg';

	export default {
		props: {
			group: Object,
			subtitle: {
				type: String,
				default: null
			}
		},
		setup(props) {
			const imageUrl = computed(() => {
				if(props.group.logo_url) return props.group.logo_url;
				else return assetsImgIconsNoImage;
			});

			return { imageUrl };
		}
	}
</script>