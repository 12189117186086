<template>
	<td class="py-4 text-break lh-xs position-relative" :colspan="colspan">
		<div class="text-xs text-muted lh-1" v-if="header">{{ header }}</div>
		<div class="text-md">{{ text }}</div>
		<div class="text-sm" v-if="subtext" v-html="subtext"></div>
		<div class="vstack gap-1" v-if="$slots.extras">
			<slot name="extras" />
		</div>
		<a href="javascript:void(0);" class="stretched-link" @click="select" v-if="hasSelect"></a>
	</td>
</template>

<script>
	import { getCurrentInstance } from 'vue';

	export default {
		emits: ['select'],
		props: {
			text: String,
			header: String,
			subtext: String,
			colspan: {
				type: Number,
				defaul: 1
			}
		},
		setup(props, { emit }) {
			const hasSelect = typeof getCurrentInstance()?.vnode.props?.onSelect === 'function';
			const select = () => emit('select');

			return { hasSelect, select };
		}
	}
</script>