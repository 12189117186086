import Repository from '../Repository';

const resource = '/account/stat';

export default {
	sources() {
		return Repository.get(`${resource}/sources`);
	},
	general(payload) {
		return Repository.post(`${resource}/general`, payload);
	},
	superlikes(id, params) {
		return Repository.get(`${resource}/superlikes/${id}`, { params: params });
	}
}