<template>
	<LayoutFormInputGeneral :inputId="inputId" :title="title" :subtitle="subtitle" :validation="validation">
		<VueDatePicker
				:id="inputId"
				v-model="inputValue"
				range
				locale="es"
				cancelText="Cancelar"
				selectText="Seleccionar"
				:format="format"
				:range="{ maxRange: maxRange ?? null }"
				:enable-time-picker="false"
				:preset-dates="presetDates"
				:placeholder="placeholder"
				v-focus="focus"
				autocomplete="off" />
	</LayoutFormInputGeneral>
</template>

<script>
	import { ref, watch, onMounted } from 'vue';
	import moment from 'moment';
	import VueDatePicker from '@vuepic/vue-datepicker';

	export default {
		components: { VueDatePicker },
		emits: ['update:modelValue'],
		props: {
			modelValue: Array,
			title: String,
			subtitle: String,
			placeholder: String,
			validation: Object,
			defaultRange: Number,
			maxRange: Number,
			focus: {
				type: Boolean,
				default: false
			}
		},
		setup(props, { emit }) {
			const inputId = `input-${Math.random().toString(36).substr(2, 9)}`;
			const inputValue = ref(props.modelValue);
			const presetDates = ref([
				{
					label: 'Hoy',
					value: [
						moment(),
						moment()
					]
				},
				{
					label: 'Este mes',
					value: [
						moment().startOf('month'),
						moment().endOf('month')
					]
				},
				{
					label: 'Mes anterior',
					value: [
						moment().subtract(1, 'month').startOf('month'),
						moment().subtract(1, 'month').endOf('month')
					]
				}
			]);

			const format = (dates) => {
				let date_from = moment(dates[0]).format('DD/MM/YYYY'),
					date_to = moment(dates[1]).format('DD/MM/YYYY'),
					diff = moment(dates[1]).diff(moment(dates[0]), 'days') + 1;

				return `${date_from} - ${date_to} (${diff} ${(diff == 1) ? 'día' : 'días'})`;
			}

			watch(inputValue, (newValue) => {
				emit('update:modelValue', newValue);
			});

			onMounted(() => {
				if(props.defaultRange > 1) presetDates.value.push({
					label: `Últimos ${props.defaultRange} días`,
					value: [
						moment().subtract(props.defaultRange - 1, 'day'),
						moment()
					]
				});

				if(props.maxRange > 1) presetDates.value.push({
					label: `Últimos ${props.maxRange} días`,
					value: [
						moment().subtract(props.maxRange - 1, 'day'),
						moment()
					]
				});
			});

			return { inputId, inputValue, presetDates, format };
		}
	}
</script>